import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import useDateFns from '@hooks/useDateFns'
import { useIntl } from 'react-intl'

import Container from '@objects/container'
import Headline from '@objects/headline'
import BlogList from '@components/blogList'

function Blog({ data, pageContext }) {
  const intl = useIntl()
  const { differenceInDays } = useDateFns()

  const blogFilter = get(data, 'allContentfulArticleCategory.nodes')

  const heroItem = ((hero) => {
    return hero && hero.stageImage
      ? {
          image: hero.stageImage,
          headline: hero.title,
          copy: hero.teaserCopy?.childMarkdownRemark?.html,
          date: hero.createdAt,
          link: hero.slug,
          tag: hero.category?.title,
        }
      : null
  })(get(data, 'contentfulArticleList.articleHero'))

  const blogItems = get(data, 'contentfulArticleList')
    ?.articles.sort((a, b) => differenceInDays(b.createdAt, a.createdAt))
    .map((item) => ({
      image: item.image,
      headline: item.title,
      copy: item.teaserCopy?.childMarkdownRemark?.html,
      tag: item.category?.title,
      date: item.createdAt,
      link: item.slug,
    }))

  return (
    <>
      <Container nopadding>
        <Headline
          level={1}
          className="my-8 py-1 text-3xl md:text-5xl"
          ariaLabel={intl.formatMessage({ id: 'blog.headline' })}
        >
          {intl.formatMessage({ id: 'blog.headline' })}
        </Headline>
      </Container>

      <BlogList
        hero={heroItem}
        items={blogItems}
        categoryfilter={blogFilter}
        showmore
        loadpershowmore={6}
      />
    </>
  )
}

Blog.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Blog

export const pageQuery = graphql`
  query BlogPageQuery($locale: String!) {
    allContentfulArticleCategory(
      filter: {
        node_locale: { eq: $locale }
        article: {
          elemMatch: {
            article___list: { elemMatch: { identifier: { eq: "blog" } } }
          }
        }
      }
    ) {
      nodes {
        id
        title
      }
    }

    contentfulArticleList(
      node_locale: { eq: $locale }
      identifier: { eq: "blog" }
    ) {
      articleHero {
        createdAt
        stageImage {
          fluid(quality: 80, maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        title
        category {
          title
          id
        }
        slug
      }
      articles {
        slug
        title
        teaserCopy {
          childMarkdownRemark {
            html
          }
        }
        createdAt
        image {
          fluid(quality: 80, maxWidth: 600) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          description
        }
        category {
          title
        }
      }
    }
  }
`
